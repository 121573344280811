<template>
  <div class="p-3 text-left">
    <router-link
      v-for="(item, i) in routes"
      :key="i"
      :to="{
        name: item.name,
      }"
    >
      <button
        class="m-1 bg-gradient-to-r text-sm from-gray-500 to-cyan-300 px-4 py-2 text-black font-bold rounded-lg"
      >
        {{ item.name }}
      </button>
    </router-link>
    <div
      class="hidden odd:bg-gray-100 even:bg-gray-100 bg-red-600 text-red-200 bg-green-600 text-green-200 bg-purple-600 text-purple-200 border-collapse border border-gray-300 text-left"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'AllRoutes',
  computed: {
    routes() {
      return this.$router
        .getRoutes()
        .filter(
          (item) =>
            !['AllRoutes', 'LayoutDefault', 'LayoutPanel'].includes(item.name)
        )
    },
  },
}
</script>

<style></style>
